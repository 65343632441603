import './App.css';
import styled from 'styled-components';
import { io } from 'socket.io-client';
import { useState, useEffect, useRef } from 'react';
import Youtube, { YouTubeProps, YouTubePlayer } from 'react-youtube';
import { isMobile } from 'react-device-detect';

const socket = io();

function App() {
    const [data, setData] = useState<ICurrentSong>(initData);
    const initialized = useRef(false)
    const videoPlayed = useRef(false)
    const [currTime, setCurrTime] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isError, setError] = useState<boolean>(false);
    const [isPlayerReady, setPlayerReady] = useState<boolean>(false);
    const player = useRef<YouTubePlayer>();

    const storedMute: string = localStorage.getItem('ismute') || 'false';
    const storedVolume: string = localStorage.getItem('volume') || '100';
    const [isPlay, setPlay] = useState<boolean>(false);
    const [isMute, setMute] = useState<boolean>(storedMute === 'true');
    const [volume, setVolume] = useState<number>(parseInt(storedVolume));
    const [modalShow, setModal] = useState<boolean>(isMobile);

    useEffect(() => {
        // console.log('useEffect')
        const fetchData = async () => {
            try {
                const res = await fetch('/api/getCurrentInfo');
                const json = await res.json();
                setData(json);
                setCurrTime(json.currTime);
                setLoading(false);
                if(!initialized.current) {
                    setInterval(() => {
                        setCurrTime((prevVal) => {
                            // console.log(data)
                            // if(prevVal + 1 > data.videoLength) return prevVal;
                            return prevVal + 1
                        });
                    }, 1000);
                    initialized.current = true;
                }
            }catch(e){
                console.error(e);
                setLoading(false);
                setError(true);
            }
        }

        socket.on('update', (songInfo) => {
            setData(songInfo);
            player.current.cueVideoById(songInfo.link.split('watch?v=')[1]);
            setCurrTime(-1);
            console.log('songUpdate', isPlay)
        })

        fetchData();
    }, [])


    let rankChange;
    let fontColor = '#c8c8c8';
    if(!!!data.changes) {
        rankChange = '─  0';
    }else if(data.changes === -999) {
        fontColor = '#f7c436';
        rankChange = 'NEW!';
    }else{
        if(data.changes > 0) {
            fontColor = '#d10404';
            rankChange = '▲  ' + data.changes;
        }else {
            fontColor = '#3025cc';
            rankChange = '▼  ' + (data.changes * -1);
        }
    }

    const updateDate = new Date(data.lastUpdate);

    let currMin = Math.floor(currTime / 60);
    let currSec = currTime % 60;
    if(currMin < 0) currMin = 0;
    if(currSec < 0) currSec = 0;
    const currMinutes = String(currMin).padStart(2, '0');
    const currSeconds = String(currSec).padStart(2, '0');

    const totalMinutes = String(Math.floor(data.videoLength / 60)).padStart(2, '0');
    const totalSeconds = String(data.videoLength % 60).padStart(2, '0');

    // let videoPlayed = false;
    const _onStateChange: YouTubeProps['onStateChange'] = (event) => {
        console.log('stateChange', event.data)
        if(event.data === 1) {
            if(videoPlayed.current) {
                event.target.seekTo(currTime);
                videoPlayed.current = false;
            }else if(!isPlay) {
                onPlayClick();
            }
        }else if(event.data === 2) {
            setPlay(false);
        }

        if(event.data === 5 && isPlay) {
            event.target.playVideo();
            videoPlayed.current = true;
        }
    }

    const opts: YouTubeProps['opts'] = {
        playerVars: {
            autoplay: 1
        }
    }

    const _onYTReady: YouTubeProps['onReady'] = (event) => {
        console.log('YT API Ready!');
        // console.log('onready_currTime', currTime);
        event.target.cueVideoById(data.link.split('watch?v=')[1]);
        event.target.setVolume(volume);
        if(isMute) event.target.mute();
        else event.target.unMute();
        player.current = event.target;
        setPlayerReady(true);
    }

    const onPlayClick = () => {
        if(!!!player.current) return;
        if(isPlay) player.current.pauseVideo();
        else {
            console.log('playBtnClicked', currTime);
            videoPlayed.current = true;
            player.current.playVideo();
        }

        setPlay(!isPlay);
    }

    const onMuteClick = () => {
        if(!!!player.current) return;
        if(isMute) player.current.unMute();
        else player.current.mute();
        localStorage.setItem('ismute', (!isMute ? 'true' : 'false'));
        setMute(!isMute);
    }

    return (
        <AppRoot>
            {modalShow ? (<div style={{
                zIndex: 99999, 
                position: 'fixed', 
                width: '100vw', 
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.7)'
            }}>
                <div style={{
                    position: 'absolute', 
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '2em',
                    width: '70vw',
                    borderRadius: '1em',
                    backgroundColor: 'white',
                }}>
                    <h2 style={{margin: 0}}>잠시만요!</h2>
                    <span>모바일로 접속중이시군요!<br/>아직 모바일용 페이지가 준비되지 않아 페이지가 깨져 보일수 있습니다.</span><br/>
                    <button style={{marginTop: '1em'}} onClick={() => setModal(false)}>닫기</button>
                </div>
            </div>) : null}
            {isLoading ? 
            (<Loading>로딩중입니다...</Loading>) :
            ((isError) ? (
            <div>
                <Loading>사이트를 불러오는 중 문제가 발생하였습니다.</Loading>
                <button style={{margin: '0 auto'}} onClick={() => window.location.reload()}>다시 로드하기</button>
            </div>) : (
            <div style={{
                width: '100%',
                height: '100%'
                }}>
                <BGDiv>
                    <Youtube 
                        // videoId={data.link.split('watch?v=')[1]}
                        opts={opts}
                        onReady={_onYTReady} onStateChange={_onStateChange}
                        style={{display: 'none'}}></Youtube>
                    <BG id="bg" src={data.thumbnailUrl}></BG>
                </BGDiv>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                }}>
                    <SongInfoDiv>
                        <RankDiv>
                            <div style={{
                                position: 'relative',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <span style={{
                                    fontWeight: '500',
                                    fontSize: '3.5vh'
                                }}>RANK </span>
                                <span style={{
                                    fontWeight: '500',
                                    fontSize: '8.5vh'
                                }}>{data.rank}</span><br/>
                                <span style={{
                                    color: fontColor,
                                    fontSize: '2.5vh',
                                    fontWeight: 500
                                }}>{rankChange}</span>
                            </div>
                        </RankDiv>
                        <InfoDiv style={{
                            display: 'flex',
                            flexDirection: 'column' 
                        }}>
                            <span style={{
                                margin: 0,
                                marginTop: '2vh',
                                wordWrap: 'break-word',
                                fontSize: '6vh',
                                fontWeight: 500,
                            }}>{data.title} </span>
                            <span style={{
                                fontSize: '2.2vh',
                            }}>Artist: {data.artists} | Singer: {data.singers}</span>
                            <div style={{
                                marginTop: 'auto',
                                marginBottom: '2vh',
                                width: '98%',
                            }}>
                                <div>
                                    <span>{data.publish} | 조회수 {new Intl.NumberFormat('ko-KR', {notation: 'compact', maximumFractionDigits: 1}).format(data.viewCount)}회 | 좋아요 {new Intl.NumberFormat('ko-KR', {notation: 'compact', maximumFractionDigits: 1}).format(data.likes)}개</span>
                                    <div style={{
                                        display: (isPlayerReady ? 'inline-block' : 'none'),
                                        float: 'right'
                                    }}>
                                    </div>
                                </div>
                                <div style={{
                                    height: '1.5vh',
                                    width: '100%',
                                    marginTop: '1vh',
                                    backgroundColor: 'gray',
                                    borderRadius: '0.75vh',
                                }}>
                                    <div id="progress" style={{
                                        width: ((currTime / data.videoLength) * 100) + '%',
                                        height: '1.5vh',
                                        borderRadius: '0.75em',
                                        transition: 'linear',
                                        transitionDuration: '1.00s',
                                        backgroundColor: 'white'
                                    }}></div>
                                </div>
                                <span id="currTime">{currMinutes}:{currSeconds}</span>
                                <span style={{float: 'right'}}>{totalMinutes}:{totalSeconds}</span>
                                <div>
                                    <button onClick={onPlayClick} style={{background: 'none', border: 'none', marginLeft: '-0.5em', marginTop: '0.5em'}}>{isPlay ? 
                                    (<svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z"/></svg>) :
                                    (<svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg>)}</button>
                                    <button onClick={onMuteClick} style={{background: 'none', border: 'none'}}>{isMute ? 
                                    (<svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M19 7.358v15.642l-8-5v-.785l8-9.857zm3-6.094l-1.548-1.264-3.446 4.247-6.006 3.753v3.646l-2 2.464v-6.11h-4v10h.843l-3.843 4.736 1.548 1.264 18.452-22.736z"/></svg>) :
                                    (<svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M6 7l8-5v20l-8-5v-10zm-6 10h4v-10h-4v10zm20.264-13.264l-1.497 1.497c1.847 1.783 2.983 4.157 2.983 6.767 0 2.61-1.135 4.984-2.983 6.766l1.498 1.498c2.305-2.153 3.735-5.055 3.735-8.264s-1.43-6.11-3.736-8.264zm-.489 8.264c0-2.084-.915-3.967-2.384-5.391l-1.503 1.503c1.011 1.049 1.637 2.401 1.637 3.888 0 1.488-.623 2.841-1.634 3.891l1.503 1.503c1.468-1.424 2.381-3.309 2.381-5.394z"/></svg>)}</button>
                                    <input style={{height: '30px'}} disabled={isMute} type='range' min='0' max='100' value={volume} onChange={(event) => { setVolume(parseInt(event.target.value)); localStorage.setItem('volume', String(volume)); if(!!player.current) player.current.setVolume(volume); }}/>
                                </div>
                            </div>
                        </InfoDiv>
                    </SongInfoDiv>
                    <div style={{
                        // position: 'relative',
                        // height: '100%',
                        // maxHeight: '67vh'
                        flexGrow: 1
                    }}>
                        <Thumbnail src={data.thumbnailUrl}/>
                        <span style={{display: 'none'}}>리엑트 넘호 어려워요</span>
                        <div style={{
                            position: 'absolute',
                            bottom: '0.3em',
                            left: '0.3em',
                            color: 'rgba(255,255,255,0.7)',
                            fontWeight: 500,
                            fontSize: '1em',
                        }}>
                            <span>vocalRadio v2.0</span><br/>
                            <a style={{
                                textDecoration: 'none',
                                color: 'rgba(255,255,255,0.7)'
                            }} href={`https://vocaloard.injpok.tokyo/?d=${updateDate.getFullYear()}-${updateDate.getMonth() + 1}-${updateDate.getDate()}&k=3&p=2&s=4&t=1,2`}>{updateDate.getFullYear()}년 {updateDate.getMonth() + 1}월 {updateDate.getWeek(6)}주차 보컬로이드 차트</a>
                        </div>
                        <a href="https://www.buymeacoffee.com/bass9030" target="_blank" style={{
                            position: 'absolute',
                            bottom: '0.3em',
                            right: '0.3em',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '1em',
                            backgroundColor: '#FFDD00',
                            borderRadius: '0.5em',
                            padding: '0.2em 0.5em 0.2em 0.5em',
                            textDecoration: 'none',
                            color: 'black'
                        }}>
                            <img style={{height: '1em', marginRight: '0.25em'}} src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee"/>
                            <span style={{fontSize: '0.75em', lineHeight: '1em'}}>Buy me a coffee!</span>
                        </a>
                    </div>
                </div>
            </div>))}
        </AppRoot>
    )
}

export default App;


const AppRoot = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
`

const Loading = styled.h3`
    text-align: center;
`;


const BGDiv = styled.div`
    position: absolute;
    z-index: -99;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
`

const BG = styled.img`
    height: 105%;
    width: 105%;
    transform: translate(-2.5%, -2.5%);
    transition:opacity 0.5s linear;
    object-fit:cover;
    filter: blur(10px);
`

const Thumbnail = styled.img`
    border-radius: 1em;
    height: 50vh;
    @media screen and (max-width: 750px) {
        height: auto;
        width: 90vw;
    }
    transition:opacity 0.5s linear;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
`

const SongInfoDiv = styled.div`
    display: block;
    width: 100vw;
    min-height: 33vh;
    height: auto;
    background-color: rgba(1, 1, 1, 0.7);
    color:white;
`

const RankDiv = styled.div`
    display: inline-block;
    float: left;
    width: 20%;
    height: 33vh;
    margin: 0;
    padding: 0;
    text-align: center;
`

const InfoDiv = styled.div`
    display: inline-block;
    min-height: 33vh;
    margin-left: 1.5%;
    margin-right: 0;
    height: auto;
    width: 78.5%;
    box-sizing: border-box;
`

interface ICurrentSong {
    rank: number;
    link: string;
    title: string;
    artists: string;
    singers: string;
    publish: string;
    thumbnailUrl: string;
    viewCount: number;
    likes: number;
    videoLength: number;
    changes: number;
    currTime: number;
    lastUpdate: number;
}

const initData = {
    "rank": 0,
    "link": "",
    "title": "",
    "artists": "",
    "singers": "",
    "publish": "",
    "thumbnailUrl": "",
    "viewCount": 0,
    "likes": 0,
    "videoLength": 0,
    "changes": 0,
    "currTime": 0,
    "lastUpdate": 0
};

declare global {
    interface Date {
        getWeek(dowOffset: number): number;
    }
}

Date.prototype.getWeek = function(dowOffset) {
    /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; // dowOffset이 숫자면 넣고 아니면 0
    var newYear = new Date(this.getFullYear(), this.getMonth(), 1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
            let nYear = new Date(this.getFullYear() + 1, 0, 1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    } else {
        weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
};